import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/dashboard/PageView.vue")
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/pages/settings/PageView.vue")
        },
        {
          path: "/settings/product",
          name: "settings-product",
          component: () => import("@/view/pages/settings/product/PageView.vue")
        },
        {
          path: "/settings/insurer",
          name: "settings-insurer",
          component: () => import("@/view/pages/settings/insurer/PageView.vue")
        },
        {
          path: "/calculator/auto",
          name: "calculator-auto",
          component: () => import("@/view/pages/calculator/car/PageView.vue")
        },
        {
          path: "/calculator/property",
          name: "calculator-property",
          component: () => import("@/view/pages/calculator/property/PageView.vue")
        },
        {
          path: "/calculator/life",
          name: "calculator-life",
          component: () => import("@/view/pages/calculator/life/PageView.vue")
        },
        {
          path: "/calculator/travel",
          name: "calculator-travel",
          component: () => import("@/view/pages/calculator/travel/PageView.vue")
        },
        {
          path: "/register-policy",
          name: "register-policy",
          component: () => import("@/view/pages/register-policy/PageView.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/PageView.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404",
      component: () => import("@/view/layout/Layout")
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/not-found/PageView")
    }
  ]
});
