import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const INSURANCE_GROUPS_RESOURCE = "insurance_groups";
export const INSURERS_RESOURCE = "insurers";
export const PRODUCT_TYPES_RESOURCE = "products";

// action types
export const INSURANCE_GROUPS_LIST = "insuranceGroupsList";
export const INSURER_LIST = "insurerList";
export const PRODUCT_TYPES_LIST = "productTypesList";
export const INSURER_ADD = "insurerAdd";
export const INSURER_EDIT = "insurerEdit";

export const CLEAR_INSURANCE_GROUPS = "clearInsuranceGroups";
export const CLEAR_INSURERS = "clearInsurers";
export const CLEAR_PRODUCT_TYPES = "clearProductTypes";

// mutation types
export const SET_INSURANCE_GROUPS = "setInsuranceGroups";
export const SET_INSURERS = "setInsurers";
export const SET_PRODUCT_TYPES = "setProductTypes";

export const PURGE_INSURANCE_GROUPS = "purgeInsuranceGroups";
export const PURGE_INSURERS = "purgeInsurers";
export const PURGE_PRODUCT_TYPES = "purgeProductTypes";

export const SET_ERROR = "setError";
export const SET_STATE = "setState";

const state = {
  errors: null,
  insuranceGroups: [],
  insurers: [],
  productTypes: [],
  isInsuranceGroupListLoading: false,
  isInsurersLoading: false,
  productTypesLoading: false
};

const getters = {
  insuranceGroups(state) {
    return state.insuranceGroups;
  },
  insurers(state) {
    return state.insurers;
  },
  productTypes(state) {
    return state.productTypes;
  },
  isInsuranceGroupListLoading(state) {
    return state.isInsuranceGroupListLoading;
  },
  isInsurersLoading(state) {
    return state.isInsurersLoading;
  },
  isProductTypesLoading(state) {
    return state.isProductTypesLoading;
  }
};

const actions = {
  [INSURER_EDIT](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.update(INSURERS_RESOURCE, payload.uuid, payload)
        .then(() => {
          let insurers = context.state.insurers;
          let newPayload = { ...payload };
          const index = insurers.findIndex(o => o.uuid === newPayload.uuid);
          if (index !== -1) {
            insurers.splice(index, 1, newPayload);
            context.commit(SET_INSURERS, insurers);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_INSURERS);
    }
  },
  [INSURER_ADD](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(INSURERS_RESOURCE, payload)
        .then(({ data }) => {
          let insurers = context.state.insurers;
          let newPayload = { ...payload };
          newPayload.uuid = data.payload.uuid;
          insurers.push(newPayload);
          context.commit(SET_INSURERS, insurers);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_INSURERS);
    }
  },
  [INSURANCE_GROUPS_LIST](context, requestPayload) {
    context.commit(SET_STATE, { type: "isInsuranceGroupListLoading", value: true });
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(INSURANCE_GROUPS_RESOURCE, requestPayload)
        .then(({ data }) => {
          context.commit(SET_INSURANCE_GROUPS, data.payload);
          context.commit(SET_STATE, {
            type: "isInsuranceGroupListLoading",
            value: false
          });
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          context.commit(SET_STATE, {
            type: "isInsuranceGroupListLoading",
            value: false
          });
        });
    } else {
      context.commit(PURGE_INSURANCE_GROUPS);
    }
  },
  [INSURER_LIST](context, requestPayload) {
    context.commit(SET_STATE, { type: "isInsurersLoading", value: true });
    if (JwtService.getToken()) {
      ApiService.setHeader();
      let queryParams = {};
      if (requestPayload !== undefined) {
        queryParams = {
          insurance_group_uuid: requestPayload.insuranceGroup
        };
      }
      ApiService.get(INSURERS_RESOURCE, "", queryParams)
        .then(({ data }) => {
          context.commit(SET_INSURERS, data.payload);
          context.commit(SET_STATE, {
            type: "isInsurersLoading",
            value: false
          });
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          context.commit(SET_STATE, {
            type: "isInsurersLoading",
            value: false
          });
        });
    } else {
      context.commit(PURGE_INSURERS);
    }
  },
  [PRODUCT_TYPES_LIST](context, requestPayload) {
    context.commit(SET_STATE, { type: "isProductTypesLoading", value: true });
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const queryParams = {
        insurance_group_uuid: requestPayload.insuranceGroup,
        insurer_uuid: requestPayload.insurer,
      };
      ApiService.get(
        PRODUCT_TYPES_RESOURCE,
        "",
        queryParams
      )
        .then(({ data }) => {
          context.commit(SET_PRODUCT_TYPES, data.payload);
          context.commit(SET_STATE, {
            type: "isProductTypesLoading",
            value: false
          });
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          context.commit(SET_STATE, {
            type: "isProductTypesLoading",
            value: false
          });
        });
    }
  },
  [PURGE_INSURANCE_GROUPS](context) {
    context.commit(PURGE_INSURANCE_GROUPS);
  },
  [PURGE_INSURERS](context) {
    context.commit(PURGE_INSURERS);
  },
  [PURGE_PRODUCT_TYPES](context) {
    context.commit(PURGE_PRODUCT_TYPES);
  }
};

const mutations = {
  [SET_INSURANCE_GROUPS](state, insuranceGroups) {
    state.insuranceGroups = insuranceGroups;
  },
  [SET_INSURERS](state, insurers) {
    state.insurers = insurers;
  },
  [SET_PRODUCT_TYPES](state, productTypes) {
    state.productTypes = productTypes;
  },
  [PURGE_INSURANCE_GROUPS](state) {
    state.insuranceGroups = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [PURGE_INSURERS](state) {
    state.insurers = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [PURGE_PRODUCT_TYPES](state) {
    state.productTypes = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [CLEAR_INSURANCE_GROUPS](state) {
    state.insuranceGroups = [];
  },
  [CLEAR_INSURERS](state) {
    state.insurers = [];
  },
  [CLEAR_PRODUCT_TYPES](state) {
    state.productTypes = [];
  },
  [SET_STATE](state, { type, value }) {
    state[type] = value;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
