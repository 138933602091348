const ID_TOKEN_KEY = "token";
const ID_REFRESH_TOKEN_KEY = "refreshToken";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRefreshToken = refreshToken => {
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, refreshToken);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyRefreshToken = () => {
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, getRefreshToken, saveRefreshToken, destroyRefreshToken  };
