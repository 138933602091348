import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const RENEWAL_RESOURCE = "mock/renewal";

// action types
export const RENEWAL_LIST = "renewalList";
export const RENEWAL_DELETE = "renewalDelete";
export const RENEWAL_ADD = "renewalAdd";
export const RENEWAL_EDIT = "renewalEdit";
export const CLEAR_RENEWALS = "clearRenewals";

// mutation types
export const SET_RENEWALS = "setRenewals";
export const SET_ERROR = "setError";
export const PURGE_RENEWALS = "purgeRenewals";

const state = {
  errors: null,
  renewals: []
};

const getters = {
  renewals(state) {
    return state.renewals;
  }
};

const actions = {
  [RENEWAL_LIST](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(RENEWAL_RESOURCE, payload)
        .then(({ data }) => {
          context.commit(SET_RENEWALS, data.data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_RENEWALS);
    }
  },
  [RENEWAL_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(RENEWAL_RESOURCE, id)
        .then(() => {
          let renewals = context.state.renewals;
          const index = renewals.findIndex(o => o.id === id);
          if (index !== -1) {
            renewals.splice(index, 1);
            context.commit(SET_RENEWALS, renewals);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_RENEWALS);
    }
  },
  [RENEWAL_ADD](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(RENEWAL_RESOURCE, payload)
        .then(({ data }) => {
          let renewals = context.state.renewals;
          let newPayload = { ...payload };
          newPayload.id = data.data.id;
          newPayload.imagePath = data.data.imagePath;
          renewals.push(newPayload);
          context.commit(SET_RENEWALS, renewals);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_RENEWALS);
    }
  },
  [RENEWAL_EDIT](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.put(RENEWAL_RESOURCE, payload)
        .then(({ data }) => {
          let renewals = context.state.renewals;
          let newPayload = { ...payload };
          const index = renewals.findIndex(o => o.id === newPayload.id);
          newPayload.imagePath = data.data.imagePath;
          if (index !== -1) {
            renewals.splice(index, 1, newPayload);
            context.commit(SET_RENEWALS, renewals);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_RENEWALS);
    }
  },
  [CLEAR_RENEWALS](context) {
    context.commit(CLEAR_RENEWALS);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_RENEWALS](state, renewals) {
    state.renewals = renewals;
  },
  [PURGE_RENEWALS](state) {
    state.renewals = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [CLEAR_RENEWALS](state) {
    state.renewals = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
