import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
Vue.config.productionTip = false;

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/_init.scss";

import VueMask from "v-mask";
Vue.use(VueMask);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import clickOutside from "./core/utils/directive-clickoutside";
Vue.directive("click-outside", clickOutside);

ApiService.init(store, router);
// import MockService from "@/core/mock/mock.service";
// MockService.init();

import "@/core/plugins/inline-svg";

router.beforeEach((to, from, next) => {
  store
    .dispatch(VERIFY_AUTH)
    .then(next)
    .catch(() => router.push({ name: "login" }));

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
