const state = {
  errors: null
};

const getters = {};

const actions = {};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
